
import { defineComponent, onMounted, ref } from "vue";
import { requiredField } from "@/util/form-rules";
import FormLoading from "@/components/layout/FormLoading.vue";
import useForm from "@/modules/useForm";
import BaseService from "@/services/admin/BaseService";
import Divisao from "@/interfaces/Divisao";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import Disciplina from "@/interfaces/Disciplina";
import Parametro from "@/interfaces/Parametro";
import TipoRedacao from "@/interfaces/TipoRedacao";
import router from "@/router";
import { useRoute } from "vue-router";
import store from "@/store";

interface Form {
  id: string;
  titulo: string;
  texto: string;
  imagem: string | null;
  nota_maxima: number;
  tipo_redacao_id: string;
  divisao_id: string;
  disciplina_id: string;
  curso_id: string | null;
  data_inicio: string;
  data_fim: string;
  arquivo: string;
  parametros: any[];
}

const baseForm: Form = {
  id: "",
  titulo: "",
  texto: "",
  imagem: null,
  nota_maxima: 0,
  tipo_redacao_id: "",
  divisao_id: "",
  disciplina_id: "",
  curso_id: null,
  data_inicio: "",
  data_fim: "",
  arquivo: "",
  parametros: [],
};

export default defineComponent({
  components: { FormLoading },
  setup(props, { emit }) {
    const route = useRoute();
    const $q = useQuasar();
    const divisoes = ref<Divisao[]>([]);
    const disciplinas = ref<Disciplina[]>([]);
    const parametros = ref<Parametro[]>([]);
    const parametrosSelect = ref<Parametro[]>([]);
    const parametrosRedacao = ref<Parametro[]>([]);
    const tipoRedacoes = ref<TipoRedacao[]>([]);
    const redacaoForm = ref<Form>(baseForm);
    const parametroAtual = ref<string>("");
    const { row, loading } = useForm(
      "redacoes",
      "redações",
      "a",
      baseForm,
      emit,
      "redacoes"
    );

    const buildFormData = (
      formData: any,
      data: any,
      parentKey: any = null
    ): FormData => {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;
        formData.append(parentKey, value);
      }
      return formData;
    };

    const submit = (): void => {
      loading.value = true;
      let formData = new FormData();
      formData = buildFormData(formData, redacaoForm.value);
      formData.append("anexo", redacaoForm.value.arquivo);
      BaseService.store("redacoes", formData)
        .then(() => {
          showNotify({
            type: "positive",
            message: `Redação cadastrada com sucesso`,
          });
          router.push({ name: "area-redacao" });
        })
        .catch((err) => {
          console.error(err.response.data.message);
          if (err.response.status == 422) {
            Object.entries(err.response.data.errors).forEach(([key, value]) => {
              const valueMessage: Array<string> = value as Array<string>;
              showNotify({
                type: "negative",
                message: valueMessage[0],
              });
            });
          } else {
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          }
        })
        .finally(() => (loading.value = false));
    };

    const getDisciplinas = (): void => {
      loading.value = true;
      BaseService.list("disciplinas")
        .then((res) => {
          disciplinas.value = res.data;
        })
        .catch((err) => {
          console.log(err);
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        })
        .finally(() => (loading.value = false));
    };

    const getDivisoes = (): void => {
      loading.value = true;
      BaseService.list("divisoes")
        .then((res) => {
          divisoes.value = res.data;
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        })
        .finally(() => (loading.value = false));
    };

    const getParametros = (): void => {
      loading.value = true;
      BaseService.list("parametros")
        .then((res) => {
          parametros.value = res.data;
          parametrosSelect.value = parametros.value;
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        })
        .finally(() => (loading.value = false));
    };

    const getTiposRedacao = (): void => {
      loading.value = true;
      BaseService.list("tipo-redacoes")
        .then((res) => {
          tipoRedacoes.value = res.data;
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        })
        .finally(() => (loading.value = false));
    };

    const addParametro = (): void => {
      const parametro = parametros.value.find(
        (p) => p.id == parametroAtual.value
      );
      if (parametro) {
        parametrosRedacao.value.push(parametro);
        redacaoForm.value.parametros.push({
          id: parametro.id,
          peso: 0,
        });
        const index = parametrosSelect.value.indexOf(parametro);
        if (index !== -1) {
          parametrosSelect.value.splice(index, 1);
        }
        parametroAtual.value = "";
      }
    };

    const removeParametro = (parametro: Parametro): void => {
      if (parametro) {
        parametrosSelect.value.push(parametro);
        const index = parametrosRedacao.value.indexOf(parametro);
        const indexR = redacaoForm.value.parametros.indexOf(
          redacaoForm.value.parametros.find((p) => p.id === parametro.id)
        );
        if (indexR !== -1) {
          redacaoForm.value.parametros.splice(indexR, 1);
        }
        if (index !== -1) {
          parametrosRedacao.value.splice(index, 1);
        }
      }
    };

    const getTotalPesos = (): number => {
      return redacaoForm.value.parametros.reduce((a, b) => {
        return parseInt(a) + parseInt(b.peso);
      }, 0);
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    const getRedacao = (redacaoId: string): void => {
      loading.value = true;
      BaseService.list(`redacoes/${redacaoId}`)
        .then((res) => {
          redacaoForm.value.id = res.data.id;
          redacaoForm.value.titulo = res.data.titulo;
          redacaoForm.value.texto = res.data.texto;
          redacaoForm.value.nota_maxima = res.data.nota_maxima;
          redacaoForm.value.tipo_redacao_id = res.data.tipo_redacao.id;
          redacaoForm.value.divisao_id = res.data.divisao.id;
          redacaoForm.value.disciplina_id = res.data.disciplina.id;
          redacaoForm.value.data_inicio = res.data.data_inicio.toString().substring(0, 10);
          redacaoForm.value.data_fim = res.data.data_fim.toString().substring(0, 10);
          res.data.parametros.forEach((value: any) => {
            parametrosRedacao.value.push(value.parametro);
            redacaoForm.value.parametros.push({
              id: value.parametro.id,
              peso: value.peso,
            });
            const index = parametrosSelect.value.findIndex(x => x.id === value.parametro.id);
            if (index !== -1) {
              parametrosSelect.value.splice(index, 1);
            }
            parametroAtual.value = "";
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (loading.value = false));
    };

    onMounted(() => {
      redacaoForm.value = baseForm;
      if (store.getters.divisaoId) {
        redacaoForm.value.divisao_id = store.getters.divisaoId;
      }
      getDivisoes();
      getDisciplinas();
      getParametros();
      getTiposRedacao();
      if (route.params.id) {
        getRedacao(route.params.id.toString());
      }
    });

    return {
      redacaoForm,
      row,
      requiredField,
      submit,
      loading,
      getDivisoes,
      showNotify,
      disciplinas,
      divisoes,
      parametros,
      parametrosSelect,
      parametrosRedacao,
      tipoRedacoes,
      parametroAtual,
      addParametro,
      removeParametro,
      getTotalPesos,
      store,
    };
  },
});
